import React, { useCallback, useMemo } from 'react';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
  IASPTabelSorteringOpties,
} from './types';
import { AutoSizer, Index, ScrollSync } from 'react-virtualized';
import Header, { ASP_STANDAARD_HEADER_HOOGTE, IHeaderProps, KolomRenderMap } from './Header';
import Body, { IBodyProps, ILeegComponentProps, IRijenGerenderedIndex } from './Body';
import { ITableDataProps } from './Body/TableData';
import { ScrollSyncChildProps } from 'react-virtualized/dist/es/ScrollSync';
import { Size } from 'react-virtualized/dist/es/AutoSizer';
import { IUitgeklapteRijProps } from './Body/UitgeklapteRij';
import { IPaginatiePositie } from '../../../api';
import { IWijzigenTableDataProps } from './Body/WijzigenTableData';
import { IVerwijderenTableDataProps } from './Body/VerwijderenTableData';
import { IVigerendTableDataProps } from './Body/VigerendTableData';
import { Kleur } from '../../../bedrijfslogica/constanten';
import BezigOverlay from './BezigOverlay';

interface IProps<TId extends keyof any, TKey extends keyof any, TRow> {
  rijen: Record<number, TRow> | TRow[];
  kolommen: ASPKolom<TKey, TRow>[];
  totaalAantalRijen?: number;
  meerRijenBatchGrootte?: number;
  rijHoogte?: ((rij: TRow) => number) | number;
  headerHoogte?: number;
  tdComponent?: React.ComponentType<ITableDataProps<TKey, TRow>>;
  leegComponent?: React.ComponentType<ILeegComponentProps>;
  uitgeklapt?: TId[];
  onUitgeklaptChange?: (uitgeklapt: TId[]) => void;
  keyExtractor: (rij: TRow) => TId;
  uitgeklapteRijComponent?: React.ComponentType<IUitgeklapteRijProps<TKey, TRow>>;
  uitgeklapteRijHoogte?: ((rij: TRow) => number) | number;
  selectie?: TId[];
  onSelectieChange?: (selectie: TId[]) => void;
  magRijSelecteren?: (rij: TRow) => boolean;
  vigerend?: TId[];
  sortering?: IAspKolomSorteringItem<TKey>[];
  onSorteringChange?: (sortering: IAspKolomSorteringItem<TKey>[]) => void;
  sorteringOpties?: IASPTabelSorteringOpties<TKey>;
  gefocustRij?: TId;
  ongefocustRijChange?: (focusRij: TId) => void;
  onToevoegenRij?: () => void;
  onWijzigenRij?: (rij: TRow, index: number) => Promise<void>;
  onVerwijderenRij?: (rij: TRow) => Promise<void>;
  lokaalSorteren?: boolean;
  onExtraRijenAangevraagd?: (positie: IPaginatiePositie) => Promise<void>;
  bodyComponent?: React.ComponentType<IBodyProps>;
  magRijVerwijderen?: (rij: TRow) => boolean;
  magRijWijzigen?: (rij: TRow) => boolean;
  verwijderenRijConfirmatie?:
    | ((rij: TRow, verwijderen: () => Promise<void>) => Promise<void>)
    | null;
  headerComponent?: React.ComponentType<IHeaderProps>;
  magRijUitklappen?: (rij: TRow) => boolean;
  onRijenGerendered?: (index: IRijenGerenderedIndex) => void;
  verwijderenTdComponent?: React.ComponentType<IVerwijderenTableDataProps<TRow>>;
  wijzigenTdComponent?: React.ComponentType<IWijzigenTableDataProps<TRow>>;
  verwijderenKolomBreedte?: number;
  wijzigenKolomBreedte?: number;
  vigerendTdComponent?: React.ComponentType<IVigerendTableDataProps<TRow>>;
  scrollNaarIndex?: number;
  statusbalkWeergeven?: boolean;
  isBezig?: boolean;
}

const ASPTabel = <TId extends keyof any, TKey extends keyof any, TRow>(
  props: IProps<TId, TKey, TRow>,
) => {
  return (
    <BezigOverlay isBezig={props.isBezig ?? false}>
      <div className="d-flex flex-fill flex-column">
        <div className="d-flex flex-fill flex-column" style={{ overflowY: 'hidden' }}>
          <ScrollSync>
            {(scrollSyncProps) => (
              <AutoSizer>
                {(size) => {
                  return (
                    <ASPTabelInner<TId, TKey, TRow>
                      {...props}
                      scrollSyncProps={scrollSyncProps}
                      size={size}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </ScrollSync>
        </div>
        {props.statusbalkWeergeven && (
          <div
            style={{
              minHeight: 30,
              width: '100%',
              borderTop: `1px solid ${Kleur.LichtGrijs}`,
              backgroundColor: Kleur.HeelLichtGrijs,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {props.totaalAantalRijen !== undefined && (
              <div className="d-flex align-items-center">
                <span className="text-muted mr-2" style={{ fontSize: 13 }}>
                  Aantal rijen
                </span>
                <span>{props.totaalAantalRijen}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </BezigOverlay>
  );
};

export interface IGenereerKolomRenderMapParams<TKey extends keyof any, TRow> {
  kolommen: ASPKolom<TKey, TRow>[];
  heeftUitklapRij: boolean;
  heeftVigerendKolom: boolean;
  heeftSelectieKolom: boolean;
  heeftToevoegenKolom: boolean;
  heeftVerwijderenKolom: boolean;
  heeftWijzigenKolom: boolean;
  containerWidth: number;
  wijzigenKolomBreedte?: number;
  verwijderenKolomBreedte?: number;
}

export const genereerKolomRenderMap = <TKey extends keyof any, TRow>(
  params: IGenereerKolomRenderMapParams<TKey, TRow>,
): KolomRenderMap<TKey, TRow> => {
  const map: KolomRenderMap<TKey, TRow> = {};

  const paddingLeft = 10;
  const paddingRight = 10;

  map[0] = {
    kolom: 'padding',
    breedte: paddingLeft,
  };

  let idxOffset = 1;

  const totaalFlex = params.kolommen.reduce((totaal, kolom) => {
    if (kolom.breedteType === EAspKolomBreedteType.Flex) {
      return totaal + kolom.flex;
    }
    return totaal;
  }, 0);
  let totaalVast = params.kolommen.reduce((totaal, kolom) => {
    if (kolom.breedteType === EAspKolomBreedteType.Vast) {
      return totaal + kolom.vasteBreedte;
    }
    return totaal;
  }, paddingLeft + paddingRight);

  const heeftVoorgaandeSpecialeKolom = () => {
    return map[idxOffset - 1] !== undefined;
  };

  if (params.heeftVigerendKolom) {
    const breedte = heeftVoorgaandeSpecialeKolom() ? 25 : 25;
    map[idxOffset] = {
      kolom: 'vigerend',
      breedte,
    };
    idxOffset++;
    totaalVast += breedte;
  }
  if (params.heeftToevoegenKolom) {
    const breedte = heeftVoorgaandeSpecialeKolom() ? 40 : 40;
    map[idxOffset] = {
      kolom: 'toevoegen',
      breedte,
    };
    idxOffset++;
    totaalVast += breedte;
  }
  if (params.heeftSelectieKolom) {
    const breedte = heeftVoorgaandeSpecialeKolom() ? 25 : 40;
    map[idxOffset] = {
      kolom: 'selectie',
      breedte,
    };
    idxOffset++;
    totaalVast += breedte;
  }
  if (params.heeftUitklapRij) {
    const breedte = heeftVoorgaandeSpecialeKolom() ? 30 : 40;
    map[idxOffset] = {
      kolom: 'uitklap',
      breedte,
    };
    idxOffset++;
    totaalVast += breedte;
  }
  if (params.heeftWijzigenKolom) {
    const breedte = params.wijzigenKolomBreedte ?? 30;
    map[idxOffset] = {
      kolom: 'wijzigen',
      breedte,
    };
    idxOffset++;
    totaalVast += params.wijzigenKolomBreedte ?? 30;
  }
  if (params.heeftVerwijderenKolom) {
    const breedte = params.verwijderenKolomBreedte ?? 30;
    map[idxOffset] = {
      kolom: 'verwijderen',
      breedte,
    };
    idxOffset++;
    totaalVast += params.verwijderenKolomBreedte ?? 30;
  }

  params.kolommen.forEach((kolom, index) => {
    let breedte: number;

    if (kolom.breedteType === EAspKolomBreedteType.Vast) {
      breedte = kolom.vasteBreedte;
    } else if (kolom.breedteType === EAspKolomBreedteType.Flex) {
      let b = (kolom.flex / totaalFlex) * (params.containerWidth - totaalVast);
      if (kolom.minimaleVasteBreedte !== undefined) {
        b = Math.max(b, kolom.minimaleVasteBreedte);
      }
      if (kolom.maximaleVasteBreedte !== undefined) {
        b = Math.min(b, kolom.maximaleVasteBreedte);
      }
      breedte = b;
    } else {
      breedte = 0;
    }

    map[index + idxOffset] = {
      kolom,
      breedte,
    };
  });

  map[params.kolommen.length + idxOffset] = {
    kolom: 'padding',
    breedte: paddingRight,
  };

  return map;
};

interface IInnerProps<TId extends keyof any, TKey extends keyof any, TRow>
  extends IProps<TId, TKey, TRow> {
  scrollSyncProps: ScrollSyncChildProps;
  size: Size;
}

const ASPTabelInner = <TId extends keyof any, TKey extends keyof any, TRow>(
  props: IInnerProps<TId, TKey, TRow>,
) => {
  const heeftVigerendKolom = props.vigerend !== undefined && props.vigerend.length > 0;
  const heeftSelectieKolom = props.onSelectieChange !== undefined;
  const heeftUitklapRij = props.onUitgeklaptChange !== undefined;
  const heeftToevoegenKolom = props.onToevoegenRij !== undefined;
  const heeftVerwijderenKolom = props.onVerwijderenRij !== undefined;
  const heeftWijzigenKolom = props.onWijzigenRij !== undefined;

  const kolomRenderMap = useMemo<KolomRenderMap<TKey, TRow>>(() => {
    return genereerKolomRenderMap({
      kolommen: props.kolommen,
      heeftVigerendKolom,
      heeftUitklapRij,
      heeftSelectieKolom,
      heeftToevoegenKolom,
      heeftVerwijderenKolom,
      heeftWijzigenKolom,
      containerWidth: props.size.width,
      verwijderenKolomBreedte: props.verwijderenKolomBreedte,
      wijzigenKolomBreedte: props.wijzigenKolomBreedte,
    });
  }, [
    props.kolommen,
    heeftUitklapRij,
    heeftSelectieKolom,
    heeftToevoegenKolom,
    heeftVerwijderenKolom,
    heeftWijzigenKolom,
    props.size.width,
    props.wijzigenKolomBreedte,
    props.verwijderenKolomBreedte,
    props.onSelectieChange,
    props.onUitgeklaptChange,
    props.onToevoegenRij,
    props.onVerwijderenRij,
    props.onWijzigenRij,
    props.vigerend,
  ]);

  const getColumnWidth = useCallback(
    (index: Index) => {
      const item = kolomRenderMap[index.index];
      return item.breedte;
    },
    [kolomRenderMap],
  );

  const totaalAantalRijen = useMemo(() => {
    if (props.totaalAantalRijen !== undefined) {
      return props.totaalAantalRijen;
    }
    return Object.keys(props.rijen).length;
  }, [props.rijen, props.totaalAantalRijen]);

  const rijenBron = useMemo<Record<number, TRow>>(() => {
    if (Array.isArray(props.rijen)) {
      const r = props.rijen as TRow[];
      return r.reduce(
        (acc, curr, idx) => ({
          ...acc,
          [idx]: curr,
        }),
        {},
      );
    }
    return props.rijen;
  }, [props.rijen]);

  const rijen = useMemo(() => {
    const moetLokaalSorteren =
      Object.keys(rijenBron).length === totaalAantalRijen &&
      props.lokaalSorteren &&
      props.sortering !== undefined &&
      props.sortering.length > 0;

    if (!moetLokaalSorteren) {
      return rijenBron;
    }

    const arr = Object.keys(rijenBron).map((key) => rijenBron[Number(key)]);

    for (let i = props.sortering!.length - 1; i >= 0; i--) {
      const sort = props.sortering![i];
      const kolom = props.kolommen.find((x) => x.key === sort.key)!;
      arr.sort((a, b) => {
        if (kolom.vergelijkingsfunctie !== undefined) {
          return kolom.vergelijkingsfunctie(a, b);
        }

        let links;
        let rechts;

        if (kolom.vergelijkingswaarde !== undefined) {
          links = kolom.vergelijkingswaarde(a) ?? null;
          rechts = kolom.vergelijkingswaarde(b) ?? null;
        } else {
          links = kolom.renderer?.(a) ?? null;
          rechts = kolom.renderer?.(b) ?? null;
        }

        if (links === null && rechts === null) {
          return 0;
        }
        if (links === null) {
          return 1;
        }
        if (rechts === null) {
          return -1;
        }

        if (typeof links === 'string' && typeof rechts === 'string') {
          return links.localeCompare(rechts);
        }

        return links === rechts ? 0 : links > rechts ? 1 : -1;
      });
      if (sort.sortering === ESortering.Descending) {
        arr.reverse();
      }
    }

    return arr.reduce((acc, curr, idx) => {
      return {
        ...acc,
        [idx]: curr,
      };
    }, {});
  }, [rijenBron, totaalAantalRijen, props.lokaalSorteren, props.sortering, props.kolommen]);

  const handleToevoegenClick = useCallback(() => {
    props.onToevoegenRij?.();
  }, [props.onToevoegenRij]);

  const headerWeergeven = useMemo(() => {
    return props.kolommen.some((kolom) => kolom.label !== undefined);
  }, [props.kolommen]);
  const bodyHeight = useMemo(() => {
    const headerHoogte = props.headerHoogte ?? ASP_STANDAARD_HEADER_HOOGTE;
    const topOffset = headerWeergeven ? headerHoogte : 0;
    return props.size.height - topOffset;
  }, [headerWeergeven, props.headerHoogte, props.size.height]);

  return (
    <div className="d-flex flex-column">
      {headerWeergeven && (
        <Header
          kolommen={props.kolommen}
          width={props.size.width}
          scrollLeft={props.scrollSyncProps.scrollLeft}
          getColumnWidth={getColumnWidth}
          heeftUitklapRij={heeftUitklapRij}
          kolomRenderMap={kolomRenderMap}
          sortering={props.sortering}
          onSorteringChange={props.onSorteringChange}
          sorteringOpties={props.sorteringOpties}
          headerComponent={props.headerComponent}
          onToevoegenClick={handleToevoegenClick}
        />
      )}
      <Body<TId, TKey, TRow>
        width={props.size.width}
        height={bodyHeight}
        scrollLeft={props.scrollSyncProps.scrollLeft}
        onScroll={props.scrollSyncProps.onScroll}
        rijen={rijen}
        totaalAantalRijen={totaalAantalRijen}
        meerRijenBatchGrootte={props.meerRijenBatchGrootte}
        kolommen={props.kolommen}
        rijHoogte={props.rijHoogte}
        tdComponent={props.tdComponent}
        leegComponent={props.leegComponent}
        getColumnWidth={getColumnWidth}
        uitgeklapt={props.uitgeklapt}
        onUitgeklaptChange={props.onUitgeklaptChange}
        keyExtractor={props.keyExtractor}
        uitgeklapteRijComponent={props.uitgeklapteRijComponent}
        uitgeklapteRijHoogte={props.uitgeklapteRijHoogte}
        kolomRenderMap={kolomRenderMap}
        selectie={props.selectie}
        onSelectieChange={props.onSelectieChange}
        gefocustRij={props.gefocustRij}
        ongefocustRijChange={props.ongefocustRijChange}
        onWijzigenRij={props.onWijzigenRij}
        onVerwijderenRij={props.onVerwijderenRij}
        onExtraRijenAangevraagd={props.onExtraRijenAangevraagd}
        bodyComponent={props.bodyComponent}
        magRijVerwijderen={props.magRijVerwijderen}
        magRijWijzigen={props.magRijWijzigen}
        verwijderenRijConfirmatie={props.verwijderenRijConfirmatie}
        magRijUitklappen={props.magRijUitklappen}
        onRijenGerendered={props.onRijenGerendered}
        magRijSelecteren={props.magRijSelecteren}
        verwijderenTdComponent={props.verwijderenTdComponent}
        wijzigenTdComponent={props.wijzigenTdComponent}
        vigerend={props.vigerend}
        vigerendTdComponent={props.vigerendTdComponent}
        scrollNaarIndex={props.scrollNaarIndex}
      />
    </div>
  );
};

export default ASPTabel;
